@tailwind base;
@tailwind components;

@import "utilities.css";
@import "blog.css";
@import "calendar.css";
@import "seo.css";
@import "gallery.css";
@import "full-calendar.css";

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.errors input,
textarea {
  @apply border;
  @apply border-red-500;
}

.link {
  @apply text-link;
  @apply cursor-pointer;
}

.link:hover {
  @apply text-link-dark underline;
}

.content-with-links a {
  @apply text-link;
}

.content-with-links a:hover {
  @apply text-link-dark underline;
}

.faq-content-with-links a {
  @apply text-link underline font-semibold;
}

.faq-content-with-links a:hover {
  @apply text-link-dark;
}

@media screen and (max-width: 640px) {
  .mx-min-w-full {
    min-width: 100% !important;
  }
}

.Toastify__toast {
  padding: 0px !important;
  margin: 10px !important;
}

.Toastify__toast-body {
  padding: 0px !important;
}

.Toastify__close-button {
  margin: 10px;
}

.Toastify__toast-container {
  @apply w-full md:max-w-lg !important;
  right: 0 !important;
}

.sharebtn:hover > .sharebtn-dropdown {
  display: block;
}

.sharebtn-dropdown {
  /* @apply flex flex-col border p-4 rounded-xl absolute top-12 right-0; */
}

.editor-container {
  overflow: hidden;
}

.sun-editor * {
  overflow-y: scroll;
}

/* Override Ant Design's default pagination alignment to center the pagination */
.ant-pagination {
  justify-content: center !important;
}

.ant-input-number-group-wrapper-outlined .ant-input-number-group-addon {
  border-width: 1px;
  border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}

.ant-input-number-group-wrapper-lg .ant-input-number-group-addon {
  border-radius: 0.25rem;
}

.ant-input-number-outlined {
  border-width: 1px;
  border-color: rgba(163, 163, 163, var(--tw-border-opacity));
}

.ant-input-number-lg {
  border-radius: 0.25rem;
  font-size: 14px;
}

.button-feedback-effect {
  transition: all 0.1s ease;
}

.button-feedback-effect:active {
  background-color: rgba(74, 222, 128);
  border-color: rgba(74, 222, 128);
  color: white;
}

.js-global-error {
  display: none;
}

.scrollbar-thin {
  overflow: scroll;
  scrollbar-width: thin;
}

.triangle-edge-right {
  width: 0;
  height: 0;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 24px solid #fef2c7;
  /* Adjust the color as needed */
}

.scroll-button {
  @apply rounded-full h-9 px-0.5 pointer-events-auto w-9 appearance-none bg-white border border-gray-300 box-border text-gray-800 cursor-pointer inline-block text-sm leading-6 m-0 outline-none overflow-hidden text-center no-underline truncate align-top;
  top: -2px;
}

.scroll-button-left {
  padding-left: 0.01rem;
}

.required:after {
  content: " *";
  color: red;
  font-weight: bold;
}

/* added here to stop abrupt scroll on ReviewCard component on supplier page while navigating to #reviews */
.smooth-scroll {
  scroll-behavior: smooth;
}

.bullet-gray li::marker {
  color: gray;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@tailwind utilities;

